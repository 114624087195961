import { FC, useEffect, useState } from 'react'

import { Drawer } from '@mui/material'

import Empty from '../Empty/Empty'

import { ClientIntegration } from './CustomerIntegrationsCell'
import CustomerIntegrationsHeader from './CustomerIntegrationsHeader'
import CustomerIntegrationsSettingsPanel from './CustomerIntegrationsSettingsPanel'
import IntegrationCard from './IntegrationCard'

interface CustomerIntegrationsListProps {
  integrations: ClientIntegration[]
}

const CustomerIntegrationsList: FC<CustomerIntegrationsListProps> = ({
  integrations,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  const [selectedIntegration, setSelectedIntegration] =
    useState<ClientIntegration>(null)

  const handleIntegrationsClick = (integration: ClientIntegration) => {
    setSelectedIntegration(integration)
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setSelectedIntegration(null)
    setOpenDrawer(false)
  }

  useEffect(() => {
    if (selectedIntegration) {
      // Get existing ID
      const selectedIntegrationId = selectedIntegration?.id

      // Find it in refetch
      const updatedIntegration = integrations.find(
        (integration) => integration?.id === selectedIntegrationId,
      )

      // Update the state
      if (updatedIntegration) {
        setSelectedIntegration(updatedIntegration)
      }
    }
  }, [integrations])

  return (
    <>
      <CustomerIntegrationsHeader />

      {integrations?.length === 0 && (
        <div className="grid h-full flex-1 grow place-items-center">
          <Empty
            title="No Integrations Found"
            description="Create a new integration to connect your data."
            defaultIcon
          />
        </div>
      )}

      {integrations?.length > 0 && (
        <div>
          <p className="inline-flex rounded bg-gray-200 p-2 px-4 text-gray-600">
            All Integrations
          </p>
          <hr className="my-8" />
          <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-6">
            {integrations.map((integration, index) => {
              return (
                <IntegrationCard
                  key={index}
                  integration={integration}
                  handleIntegrationsClick={handleIntegrationsClick}
                />
              )
            })}
          </div>
        </div>
      )}
      {selectedIntegration && (
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={handleDrawerClose}
          PaperProps={{ sx: { width: '400px' } }}
        >
          <CustomerIntegrationsSettingsPanel
            integration={selectedIntegration}
          />
        </Drawer>
      )}
    </>
  )
}

export default CustomerIntegrationsList
