import React, { FC, useEffect, useState } from 'react'

import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { TextField } from '@mui/material'
import {
  UpdateClientIntegrationInput,
  UpdateClientIntegrationMutation,
  UpdateClientIntegrationMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useConfirm } from 'src/lib/hooks/Confirmation'

import Button from '../Library/Button/Button'

import { ClientIntegration } from './CustomerIntegrationsCell'
import { UPDATE_CLIENT_INTEGRATION_MUTATION } from './CustomerIntegrationsSettingsPanel'
import { FieldErrorStatus, integrationOptions } from './integrations'

interface EditCustomerIntegrationsFormProps {
  integration: ClientIntegration
  statusLoading: boolean
}

const EditCustomerIntegrationsForm: FC<EditCustomerIntegrationsFormProps> = ({
  integration,
  statusLoading,
}) => {
  const integrationConfig =
    integrationOptions[integration?.integrationType] ?? null

  const defaultErrorState: FieldErrorStatus = { error: false, message: '' }

  const [editMode, setEditMode] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [integrationAccountKey, setIntegrationAccountKey] = useState<string>('')
  const [integrationAccountKeyError, setIntegrationAccountKeyError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationAPIKey, setIntegrationAPIKey] = useState<string>('')
  const [integrationAPIKeyError, setIntegrationAPIKeyError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationAPISecret, setIntegrationAPISecret] = useState<string>('')
  const [integrationAPISecretError, setIntegrationAPISecretError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const confirmIntegrationChanges = useConfirm()

  const [updateClientIntegrationCredentials] = useMutation<
    UpdateClientIntegrationMutation,
    UpdateClientIntegrationMutationVariables
  >(UPDATE_CLIENT_INTEGRATION_MUTATION, {
    onCompleted: () => {
      setEditMode(false)
      setIsSaving(false)
      resetIntegrationSecretFields()
      toast.success('Integration Saved', {
        duration: 3000,
      })
    },
    onError: () => {
      toast.error('There was an error updating your integration.', {
        duration: 5000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['CustomerIntegrationsQuery'],
    awaitRefetchQueries: true,
  })

  const validateFields = (): boolean => {
    let validity = true

    if (
      !integrationAccountKey &&
      integrationConfig?.formFields.apiAccountKey.value
    ) {
      setIntegrationAccountKeyError({
        error: true,
        message:
          integrationConfig?.formFields.apiAccountKey.value +
          ' cannot be empty',
      })
      validity = false
    }
    if (!integrationAPIKey && integrationConfig?.formFields.apiKey.value) {
      setIntegrationAPIKeyError({
        error: true,
        message:
          integrationConfig?.formFields.apiKey.value + ' cannot be empty',
      })
      validity = false
    }
    if (
      !integrationAPISecret &&
      integrationConfig?.formFields.apiSecret.value
    ) {
      setIntegrationAPISecretError({
        error: true,
        message:
          integrationConfig?.formFields.apiSecret.value + ' cannot be empty',
      })
      validity = false
    }

    return validity
  }

  const handleSaveIntegration = async () => {
    setIsSaving(true)

    const allFieldsValid = validateFields()

    if (allFieldsValid) {
      const data: UpdateClientIntegrationInput = {
        active: integration?.active,
        apiAccountKey: integrationAccountKey,
        apiKey: integrationAPIKey,
        apiSecret: integrationAPISecret,
      }

      await updateClientIntegrationCredentials({
        variables: { id: integration?.id, input: data },
      })
    } else {
      setIsSaving(false)
    }
  }

  // Reset all secrets to empty
  const resetIntegrationSecretFields = () => {
    setIntegrationAccountKey('')
    setIntegrationAPIKey('')
    setIntegrationAPISecret('')

    setIntegrationAccountKeyError(defaultErrorState)
    setIntegrationAPIKeyError(defaultErrorState)
    setIntegrationAPISecretError(defaultErrorState)
  }

  // Validate Account Key
  useEffect(() => {
    setIntegrationAccountKeyError(defaultErrorState)

    if (
      !integrationAccountKey &&
      integrationConfig?.formFields.apiAccountKey.value
    ) {
      setIntegrationAccountKeyError({
        error: true,
        message:
          integrationConfig?.formFields.apiAccountKey.value +
          ' cannot be empty',
      })
    }
  }, [integrationAccountKey])

  // Validate API Key
  useEffect(() => {
    setIntegrationAPIKeyError(defaultErrorState)

    if (!integrationAPIKey && integrationConfig?.formFields.apiKey.value) {
      setIntegrationAPIKeyError({
        error: true,
        message:
          integrationConfig?.formFields.apiKey.value + ' cannot be empty',
      })
    }
  }, [integrationAPIKey])

  // Validate API Secret
  useEffect(() => {
    setIntegrationAPISecretError(defaultErrorState)

    if (
      !integrationAPISecret &&
      integrationConfig?.formFields.apiSecret.value
    ) {
      setIntegrationAPISecretError({
        error: true,
        message:
          integrationConfig?.formFields.apiSecret.value + ' cannot be empty',
      })
    }
  }, [integrationAPISecret])

  // Reset the integration field values each time the type changes
  useEffect(() => {
    resetIntegrationSecretFields()
  }, [integration])

  return (
    <div className="">
      <div className="flex- flex items-center justify-between gap-1 py-3">
        <div>
          <p>Settings</p>
          <p className="text-sm text-gray-400">
            Edit your integration credentials.
          </p>
        </div>
        <div className="flex gap-1">
          <Button
            fullWidth={false}
            onClick={() => {
              setEditMode(!editMode)
            }}
            disabled={isSaving || statusLoading}
            variant="outlined"
            className="py-0 pt-0.5 text-xs"
            startIcon={!editMode && <PencilSquareIcon className="h-4 w-4" />}
          >
            <span className="pt-0.5">{editMode ? 'Cancel' : 'Edit'}</span>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-1 py-3">
        <p className="text-sm">Integration Provider</p>
        <p className="h-10 text-sm text-gray-400">
          {integrationConfig?.integrationName}
        </p>
      </div>

      {integrationConfig?.formFields.apiAccountKey.value && (
        <div className="flex flex-col gap-1 py-3">
          <p className="text-sm">
            {integrationConfig?.formFields.apiAccountKey.value}
          </p>
          {editMode ? (
            <div>
              <TextField
                type={
                  integrationConfig?.formFields.apiAccountKey.hide
                    ? 'password'
                    : 'text'
                }
                disabled={isSaving}
                error={integrationAccountKeyError?.error}
                fullWidth={false}
                size="small"
                id="outlined-controlled"
                className="w-full bg-white"
                value={integrationAccountKey}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIntegrationAccountKey(event.target.value)
                }}
              />
              {integrationAccountKeyError.error && (
                <p className="p-0.5 text-xs text-red-600">
                  {integrationAccountKeyError.message}
                </p>
              )}
            </div>
          ) : (
            <p className="h-10 text-sm text-gray-400">
              {integration?.apiAccountKey ?? '-'}
            </p>
          )}
        </div>
      )}
      {integrationConfig?.formFields.apiKey.value && (
        <div className="flex flex-col gap-1 py-3">
          <p className="text-sm">
            {integrationConfig?.formFields.apiKey.value}
          </p>
          {editMode ? (
            <div>
              <TextField
                type={
                  integrationConfig?.formFields.apiKey.hide
                    ? 'password'
                    : 'text'
                }
                disabled={isSaving}
                error={integrationAPIKeyError?.error}
                fullWidth={false}
                size="small"
                id="outlined-controlled"
                className="w-full bg-white"
                value={integrationAPIKey}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIntegrationAPIKey(event.target.value)
                }}
              />
              {integrationAPIKeyError.error && (
                <p className="p-0.5 text-xs text-red-600">
                  {integrationAPIKeyError.message}
                </p>
              )}
            </div>
          ) : (
            <p className="h-10 text-sm text-gray-400">
              {integration?.apiKey ?? '-'}
            </p>
          )}
        </div>
      )}
      {integrationConfig?.formFields.apiSecret.value && (
        <div className="flex flex-col gap-1 py-3">
          <p className="text-sm">
            {integrationConfig?.formFields.apiSecret.value}
          </p>
          {editMode ? (
            <div>
              <TextField
                type={
                  integrationConfig?.formFields.apiSecret.hide
                    ? 'password'
                    : 'text'
                }
                disabled={isSaving}
                error={integrationAPISecretError.error}
                fullWidth={false}
                size="small"
                id="outlined-controlled"
                className="w-full bg-white"
                value={integrationAPISecret}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIntegrationAPISecret(event.target.value)
                }}
              />
              {integrationAPISecretError.error && (
                <p className="p-0.5 text-xs text-red-600">
                  {integrationAPISecretError.message}
                </p>
              )}
            </div>
          ) : (
            <p className="h-10 text-sm text-gray-400">
              {integration?.apiSecret ?? '-'}
            </p>
          )}
        </div>
      )}
      {editMode && (
        <Button
          loading={isSaving}
          disabled={
            isSaving ||
            statusLoading ||
            integrationAPIKeyError.error ||
            integrationAPISecretError.error ||
            integrationAccountKeyError.error
          }
          className="mt-3"
          onClick={() => {
            confirmIntegrationChanges({
              title: 'Save Changes',
              description:
                'Are you sure you want to save these changes to the integration credentials?',
            }).then((isConfirmed) => {
              if (!isConfirmed) return
              handleSaveIntegration()
            })
          }}
        >
          Save Changes
        </Button>
      )}
    </div>
  )
}

export default EditCustomerIntegrationsForm
